import React from "react";
import "./blog.css";
import { useNavigate } from "react-router-dom";

function BlogDetails({ item }) {
  const navigate = useNavigate();

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    return `${day} ${month}, ${year}`;
  }
  return (
    <div className="blogMainDiv">
      {item?.created_at && (
        <p className="blogDate">{formatTimestampToDate(item?.created_at)}</p>
      )}
      {item?.title && <p className="blogTitle">{item.title}</p>}
      {item?.description && (
        <p className="blogDesc">
          {item?.description.length > 200
            ? item?.description?.slice(0, 200)?.concat("...")
            : item?.description}
        </p>
      )}
      <p
        className="continueReadingTxt"
        onClick={() => navigate(`/blogdetails?id=${item?.id}`)}
      >
        Continue Reading
      </p>
    </div>
  );
}

export default BlogDetails;
