import React, { useState, useEffect } from "react";
import "./navbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import ContactUsModal from "../modals/ContactUsModal";
import "react-phone-input-2/lib/style.css";

const NavbarComponent = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("/");
  const [isOpen, setIsOpen] = useState(false);
  const [isContactUs, setisContactUs] = useState(false);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const navigate = useNavigate()

  return (
    <>
      <div className="navbar-div">
        <div className="main-nav">
          <div className="logo-div">
            <div className="logo" />
            <div className="logo-header-div" onClick={() => navigate("/") }>
              <span className="logo-header-text">XCELYST</span>
              <span className="logo-tagline-text">RECRUITment REINVENTED</span>
            </div>
          </div>

          <div className="nav-div-items">
            <NavLink
              className={`nav-link ${activeLink === "/" ? "active" : ""}`}
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              className={`nav-link ${activeLink === "/about" ? "active" : ""}`}
              to="/about"
            >
              About Us
            </NavLink>
            <NavLink
              className={`nav-link ${
                activeLink === "/candidate" ? "active" : ""
              }`}
              to="/candidate"
            >
              Candidates
            </NavLink>
            <NavLink
              className={`nav-link ${activeLink === "/blogs" ? "active" : ""}`}
              to="/blogs"
            >
              Blogs
            </NavLink>
            <NavLink
              className={`nav-link ${activeLink === "/team" ? "active" : ""}`}
              to="/team"
            >
              Team
            </NavLink>
            <NavLink
              className={`nav-link-contact`}
              onClick={() => setisContactUs(true)}
            >
              Contact Us
            </NavLink>
          </div>

          <div className="responsive-nav">
            <div className="menu-container">
              <div
                className={`menu-toggle ${isOpen ? "open transparent" : ""}`}
                onClick={handleToggle}
              >
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
            </div>
            <nav className={`nav-menu ${isOpen ? "open" : ""}`}>
              <div className="responsive-nav-items">
                <NavLink
                  className={`nav-link ${activeLink === "/" ? "active" : ""}`}
                  to="/"
                >
                  Home
                </NavLink>
                <NavLink
                  className={`nav-link ${
                    activeLink === "/about" ? "active" : ""
                  }`}
                  to="/about"
                >
                  About Us
                </NavLink>
                <NavLink
                  className={`nav-link ${
                    activeLink === "/candidate" ? "active" : ""
                  }`}
                  to="/candidate"
                >
                  Candidates
                </NavLink>
                <NavLink
                  className={`nav-link ${
                    activeLink === "/blogs" ? "active" : ""
                  }`}
                  to="/blogs"
                >
                  Blogs
                </NavLink>
                <NavLink
                  className={`nav-link ${
                    activeLink === "/team" ? "active" : ""
                  }`}
                  to="/team"
                >
                  Team
                </NavLink>
                <NavLink
                  className={`nav-link-contact`}
                  onClick={() => setisContactUs(true)}
                >
                  Contact Us
                </NavLink>
              </div>
            </nav>
          </div>
        </div>

        <ContactUsModal
          isContactUs={isContactUs}
          setisContactUs={setisContactUs}
        />
      </div>
    </>
  );
};

export default NavbarComponent;
