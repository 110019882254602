import React, { useCallback, useEffect, useState } from "react";
import "./blog.css";
import {
  backArrow,
  comment,
  filledLike,
  like,
  linkedIn,
  twitter,
} from "../../helper/assets";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarComponent from "../navbar/NavbarComponent";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import BlogHome from "./BlogHome";
import NewsLetter from "../newsletter/NewsLetter";
import Footer from "../footer/Footer";
import Loader from "../loader/Loader";
import axios from "axios";
import {
  BASE_URL,
  HOME_BLOG_LISTING_END_POINT,
  LIKE_API_ENDPOINT,
} from "../../helper/config";
import { Helmet } from "react-helmet";

function BlogViewMore() {
  const [homeBlogData, setHomeBlogData] = useState([]);
  const [ref, inView] = useInView({ threshold: 0 });
  const animationProps = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
  });

  const [loading, setLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [blogData, setBlogData] = useState({});
  const [blogId, setBlogId] = useState("");
  const [likeCount, setLikeCount] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const shareOnTwitter = (title, description, url) => {
    const text = `${title} - ${description}`;
    const truncatedText =
      text.length > 240 ? `${text.substring(0, 240)}...` : text; // Ensuring we have space for the URL

    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      truncatedText
    )}&url=${encodeURIComponent(url)}`;

    window.open(twitterUrl, "_blank");
  };

  const handleShare = (url, title, summary, source) => {
    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
      summary
    )}&source=${encodeURIComponent(source)}`;

    window.open(linkedInShareUrl, "_blank", "noopener,noreferrer");
  };

  const location = useLocation();
  const navigate = useNavigate();

  const homeBlogDataFunction = useCallback(async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${HOME_BLOG_LISTING_END_POINT}?blog_id=${id}`
      );
      if (response.status === 200) {
        setHomeBlogData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const blogDataFunction = useCallback(async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${HOME_BLOG_LISTING_END_POINT}/${id}`
      );
      if (response.status === 200) {
        setBlogData(response.data?.data);
        setLikeCount(response.data?.data?.like_count);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const likeHandler = () => {
    setIsLiked(!isLiked);
    if (!isLiked) {
      likeApiFunction();
    }
  };

  const likeApiFunction = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("blog_id", blogId);
      formData.append("type", "like");

      const response = await axios.post(
        `${BASE_URL}${LIKE_API_ENDPOINT}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setLoading(false);
        setLikeCount(likeCount + 1);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();
    return `${day} ${month}, ${year}`;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(location.search);
    const blogId = searchParams.get("id");
    homeBlogDataFunction(blogId);
    blogDataFunction(blogId);
    setBlogId(blogId);
    setIsLiked(false);
  }, [location]);

  const stripHtml = (html) => {
    const temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  };

  return (
    <animated.div className="main-container">
      <NavbarComponent />
      <Helmet>
        <meta property="og:title" content={blogData?.title} />
        <meta
          property="og:description"
          content={stripHtml(blogData?.blog_text)}
        />
        <meta
          property="og:url"
          content={`${window.location.origin}${location.pathname}${location.search}`}
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <div style={{ flex: 1 }}>
        <div className="viewmoreMain">
          <div className="backBtnDiv" onClick={() => navigate(-1)}>
            <img src={backArrow} className="backArrowIcon" />
            <p className="backTxt">Back</p>
          </div>

          <div className="blogMoreDetails">
            {blogData?.title && <p className="blogTitle">{blogData?.title}</p>}
            {blogData?.description && (
              <p className="blogDesc">{blogData?.description}</p>
            )}
            {blogData?.blog_text && (
              <div
                className="blog_desc-data"
                dangerouslySetInnerHTML={{ __html: blogData?.blog_text }}
              ></div>
            )}
          </div>

          <div className="datePostedbyDiv">
            {blogData?.user?.created_at && (
              <p className="blogDate">
                {formatTimestampToDate(blogData?.user?.created_at)}
              </p>
            )}
            {blogData?.user?.role && (
              <p className="postedName">Posted by : {blogData?.user?.role}</p>
            )}
          </div>

          <div className="interactBtnsMainDiv">
            <div className="nonSocialMediaDiv">
              <div
                className="likeDiv"
                onClick={() => !isLiked && likeHandler()}
              >
                <img src={isLiked ? filledLike : like} className="likeImg" />
                <p className="likeTxt">
                  {likeCount} {likeCount > 1 ? "Likes" : "Like"}
                </p>
              </div>
            </div>

            <div className="socialMediaDiv">
              <div
                className="linkedinDiv"
                onClick={() => {
                  handleShare(
                    `${window.location.origin}${location.pathname}${location.search}`,
                    blogData?.title,
                    stripHtml(blogData?.blog_text),
                    window.location.origin
                  );
                }}
              >
                <p className="shareOnTxt">Share on</p>
                <img src={linkedIn} className="socialImg" />
              </div>
              <div
                className="twitterDiv"
                onClick={() =>
                  shareOnTwitter(
                    blogData?.title,
                    stripHtml(blogData?.description),
                    `${window.location.origin}${location.pathname}${location.search}`
                  )
                }
              >
                <p className="shareOnTxt">Share on</p>
                <img src={twitter} className="socialImg" />
              </div>
            </div>
          </div>
        </div>

        <div className="viewmoreMain">
          <div ref={ref} className="blog-home-header-div mt60">
            <animated.span style={animationProps} className="blog-home-header">
              OTHER POSTS
            </animated.span>
          </div>

          {homeBlogData && (
            <div className="home-blog-list-div mt-40">
              {homeBlogData?.slice(0, 3)?.map((item) => {
                return <BlogHome data={item} />;
              })}
            </div>
          )}

          <div style={{ marginTop: "60px" }}>
            <NewsLetter />
          </div>
        </div>
      </div>
      <Footer />
      {loading && <Loader />}
    </animated.div>
  );
}

export default BlogViewMore;
